// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-error-403-jsx": () => import("./../../../src/pages/error-403.jsx" /* webpackChunkName: "component---src-pages-error-403-jsx" */),
  "component---src-pages-error-405-jsx": () => import("./../../../src/pages/error-405.jsx" /* webpackChunkName: "component---src-pages-error-405-jsx" */),
  "component---src-pages-facility-jsx": () => import("./../../../src/pages/facility.jsx" /* webpackChunkName: "component---src-pages-facility-jsx" */),
  "component---src-pages-flexible-jsx": () => import("./../../../src/pages/flexible.jsx" /* webpackChunkName: "component---src-pages-flexible-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-labels-jsx": () => import("./../../../src/pages/labels.jsx" /* webpackChunkName: "component---src-pages-labels-jsx" */),
  "component---src-pages-paper-jsx": () => import("./../../../src/pages/paper.jsx" /* webpackChunkName: "component---src-pages-paper-jsx" */),
  "component---src-pages-products-jsx": () => import("./../../../src/pages/products.jsx" /* webpackChunkName: "component---src-pages-products-jsx" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */)
}

