module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-MDX1T0NV28"]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"quality":100,"showCaptions":true,"maxWidth":650,"linkImagesToOriginal":true,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"DN Pack | Duy Nhat Packaging | Printing & Packaging Manufacturer Vietnam","short_name":"DN Pack | Packaging Manufacturer Vietnam","start_url":"https://www.dnpack.com.vn/index.html","background_color":"#dbdbdb","theme_color":"#f7f0eb","display":"standalone","icon":"src/images/duynhat/logo/DN_logo.png","icon_options":{"purpose":"any maskable"},"lang":"en","description":"DN Pack is a company specializes in printing &amp; converting paper and soft packaging mainly for food industry and consumer goods.","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"c47499fe3253bed19874666140448428"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/","/about/"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
